import React, { useEffect } from "react";
import FormHeader from "../../Component/EventForm/FormHeader";
import Button from "../../Component/AuthButton/Button";
import TransactionTable from "../../Component/Credit/TransactionTable";
import { coin } from "../../Asset";
import { connect, useDispatch } from "react-redux";
import Loader from "../../Component/UI/Loader";
import {
  getProfileInfoRequest,
  getTransitionHistoryRequest,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
interface MyCreditProps {
  cerditHistory: any;
}
const MyCredit: React.FC<MyCreditProps> = ({ cerditHistory }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactionData = [
    {
      transactionId: "TR-1",
      dateTimeCombined: "2024-05-09 14:30:00",
      planeType: "50 Invitation",
      amount: 30,
    },
    {
      transactionId: "TR-2",
      dateTimeCombined: "2024-05-08 12:45:00",
      planeType: "200 Invitation",
      amount: 75,
    },
    {
      transactionId: "TR-3",
      dateTimeCombined: "2024-05-07 09:20:00",
      planeType: "200 Invitation",
      amount: 75,
    },
    // Add more transactions as needed
  ];

  const getProfileInfo = () => {
    dispatch(getTransitionHistoryRequest());
  };
  useEffect(() => {
    getProfileInfo();
  }, []);

  const handleBack = () => {};

  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      {cerditHistory?.loading && <Loader />}
      <div className="h-full flex flex-col items-center justify-center w-full text-center">
        <FormHeader header={"My Credits"} message={""} onBack={handleBack} />
        <div className="h-full w-full flex justify-center my-6 overflow-y-auto overflow-x-hidden">
          <div className="md:w-[80%] w-[90%] lg:w-3/4 xl:w-3/4 h-max md:px-8 max-w-[40rem] px-6 bg-white flex justify-center flex-col items-center pb-16">
            <div className="w-full text-[#024773] text-[1.1rem] my-4 font-normal">
              Total Credits
            </div>
            <div className="w-full relative flex justify-center items-center">
              <img src={coin} alt="" />
              <div className="absolute text-white text-[1.5rem] font-bold">
                {cerditHistory?.data?.userWallet?.totalCredits}
              </div>
            </div>
            <div className="w-60 bg-[#0074A1] text-white rounded p-2 font-bold my-3">
              Your cash credits {cerditHistory?.data?.userWallet?.credits}
            </div>
            <div className="w-1/2">
              Your free credits {cerditHistory?.data?.userWallet?.freeCredits}
            </div>
            <div className="w-full py-2 text-[#707070] font-[200]">
              Credits are used for send Invitation, when you run out of credits
              you cannot send invitation.{" "}
            </div>
            <div className="w-full min-w-[30rem] flex justify-center">
              <Button
                lable={"Buy Credits"}
                gredient={true}
                mainStyle="w-1/3 cursor-pointer"
                buttonClick={() => navigate("/buycredit")}
              />
            </div>
            <div className="w-full text-[#024773] mt-6 font-[600] ">
              Purchase History
            </div>
            <div className="w-full mt-6 ">
              <TransactionTable
                transactionData={
                  cerditHistory?.data?.userPurchaseHistory?.transactions
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    cerditHistory: state.getCreditHistoryReducer,
  };
};

export default connect(mapStateToProps)(MyCredit);
