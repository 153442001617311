import React from "react";
import { GoogleLogout } from "react-google-login";
interface OAuthLogOut {
  onSignOut: any;
}
const OAuthLogOut: React.FC<OAuthLogOut> = ({ onSignOut }) => {
  const onSuccess = () => {
    console.log("Log Out Successfull");
    onSignOut();
  };
  return (
    <span>
      <GoogleLogout
        clientId="956209229820-kql53hoebvr8islknh8hkluqtsimo6tj.apps.googleusercontent.com"
        buttonText="Logout"
        onLogoutSuccess={onSuccess}
      />
    </span>
  );
};

export default OAuthLogOut;
