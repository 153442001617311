import React, { useState } from "react";
interface FormInvities {
  disabled: boolean;
  readOnly?: boolean;
  messageStyle?: string;
}
const FormInvities: React.FC<FormInvities> = ({
  disabled,
  readOnly,
  messageStyle,
}) => {
  const [value, setValue] = useState<any>(20);

  return (
    <div className="w-full flex  flex-col ">
      <div className="text-[0.8rem] text-left">
        <p className="text-[0.75rem] text-[#8B8B8B] font-[200]">
          {readOnly ? "Invitees will see like this" : ""}
        </p>
        <p className={`text-sm text-[#8B8B8B] font-[100] mb-4 ${messageStyle}`}>
          Invitees will see like this
        </p>
        <p className={`text-[#8B8B8B] font-[200] ${messageStyle}`}>
          Number of people attending, including you? *
        </p>
      </div>
      <div className="w-full flex flex-col items-center justify-center my-3">
        <input
          className="invitiesSlider"
          max={8}
          min={1}
          step={1}
          type="range"
          name=""
          id=""
          data-pr-tooltip=""
          disabled={disabled}
        />
        <div className="flex w-[50%] justify-between text-[0.8rem] text-[#666666] mt-2">
          <div>01</div>
          <div>04</div>
          <div>08</div>
        </div>
          <p className={`text-sm text-[#8B8B8B] font-[100] ${messageStyle}`}>
          It's an example
        </p>
      </div>
    </div>
  );
};

export default FormInvities;
