import React, { useEffect, useState } from "react";
import { contact } from "../../Asset";
import Button from "../AuthButton/Button";
import { connect, useDispatch } from "react-redux";
import {
  getContactRequest,
  getProfileInfoRequest,
  sendInvitationRequest,
  saveAndSendInvitationRequest,
} from "../../Redux/Actions";
import SelectedContact from "../../modals/SelectedContact";
import FormHeader from "./FormHeader";
import InvitationSuccess from "./InvitationSuccess";
import { useNavigate, useParams } from "react-router-dom";
import BuyCreditModal from "../../modals/BuyCreditModal";
import toast from "react-hot-toast";
import OAuthMain from "../GoogleOAuth/OAuthMain";
interface ContactPickerProps {
  contactsData: any;
  freeCredits: any;
  sendInvitation: any;
}
interface Contact {
  url: string | undefined;
  fullName: string;
  mobileNumber: string;
}

const ContactPicker: React.FC<ContactPickerProps> = ({
  contactsData,
  freeCredits,
  sendInvitation,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedContactIcon, setSelectedContactIcon] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buyCredit, setBuyCredit] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [invitationSuccessShow, setInvitationSuccess] = useState(false);
  useEffect(() => {
    dispatch(getProfileInfoRequest());
  }, []);

  useEffect(() => {
    console.log(sendInvitation);

    if (sendInvitation?.data?.statusCode === 200) {
      setInvitationSuccess(true);
    }
  }, [sendInvitation]);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    const isNumeric = /^\d+$/.test(value);
    const searchBy = isNumeric ? "MOBILE" : "NAME";
    const searchValue = isNumeric ? parseInt(value) : value;
    // @ts-ignore
    dispatch(getContactRequest(0, 0, searchBy, searchValue));
    setShowModal(true);
  };

  const handleSendInvitation = () => {
    if (selectedContacts.length > freeCredits) {
      setBuyCredit(true);
    } else {
      dispatch(
        sendInvitationRequest(
          {
            eventId: id,
            contactList: selectedContacts,
          },
          false
        )
      );
      // navigate("/");
    }
  };
  const handleSaveAndSendInvitation = () => {
    dispatch(
      saveAndSendInvitationRequest({
        eventId: id,
        contactList: selectedContacts,
      })
    );
    navigate("/");
  };

  const removeContact = (index: number) => {
    setSelectedContacts((prevContacts) =>
      prevContacts.filter((_, i) => i !== index)
    );
  };

  const handleClearSelectedContact = () => {
    setSelectedContacts([]);
  };

  return !invitationSuccessShow ? (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      {buyCredit && (
        <BuyCreditModal
          onCancel={() => setBuyCredit(false)}
          onConfirm={() => navigate("/buycredit")}
          message={`You have only ${freeCredits} free credits left in your account, Buy more credits to send invitation to more contacts.`}
        />
      )}
      <div className="h-full w-full flex flex-col items-center justify-center">
        <FormHeader
          header={"Pick your contacts"}
          message={""}
          onBack={() => {
            navigate(`/events/form/${id}/testResponse`);
          }}
        />
        <div className="h-full w-full flex justify-center my-6 overflow-scroll">
          <div className="md:w-3/4 w-[90%] lg:w-3/4 xl:w-1/2 h-max md:px-16 max-w-[40rem] px-6 bg-white flex justify-center flex-col items-center pb-16">
            <div className="w-full py-6 mx-2  font-[300] leading-6 border-b text-[#024773]">
              {selectedContacts.length === 0
                ? "Send the Invitation to all your guests!"
                : "Invite people from your contact list"}
            </div>
            <div className="w-full my-4">
              {contactsData.data.length < 1 ? (
                <div>
                  <div className="w-full mt-1 border border-red-500 rounded p-2 bg-red-100 mb-4">
                    <div className="text-sm text-red-500">
                      Please login with Google to sync your contacts.
                    </div>
                  </div>
                  <OAuthMain isLogin={contactsData.data.length < 1} />
                </div>
              ) : (
                <div>
                  <OAuthMain isLogin={contactsData.data.length < 1} />
                </div>
              )}
              <div className="flex items-center justify-start w-full p-1 relative">
                <input
                  type="text"
                  className="border rounded-lg p-2 text-sm w-full pr-10 h-12 placeholder-grey-400 placeholder:truncate"
                  placeholder="Select from your phone contact list"
                  value={inputValue} // Use inputValue for input value
                  onChange={(e) => handleInputChange(e.target.value)}
                />
                <button
                  onClick={() => setShowModal(true)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  <img
                    src={contact}
                    alt="Pick Contact"
                    className="h-5 w-5"
                    onClick={() => setSelectedContactIcon(true)}
                  />
                </button>
                <SelectedContact
                  selectedContactIcon={selectedContactIcon}
                  open={showModal}
                  onClose={() => setShowModal(false)}
                  handleSelectedContact1={(
                    fullName: string,
                    mobileNumber: string,
                    url: string
                  ) =>
                    setSelectedContacts((prevContacts) => [
                      ...prevContacts,
                      { fullName, mobileNumber, url },
                    ])
                  }
                  handleSelectedContact2={(
                    fullName: string,
                    mobileNumber: string,
                    url: string
                  ) =>
                    setSelectedContacts((prevContacts) => [
                      ...prevContacts,
                      { fullName, mobileNumber, url },
                    ])
                  }
                  contactsData={contactsData?.data}
                  // @ts-ignore
                  selectedContacts={selectedContacts}
                  setSelectedContacts={setSelectedContacts}
                />
              </div>
            </div>
            {selectedContacts.length === 0 ? (
              <>
                <div className="w-full text-[#808080] font-[200] text-sm">
                  Note: You can send this invitation to only {freeCredits}
                </div>
                <div className="text-[#808080] font-[200] text-sm">
                  using this Free version want to send it more
                  <span
                    className="underline cursor-pointer block text-[#024773]"
                    onClick={() => navigate("/buycredit")}
                  >
                    Buy Credits.
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="w-full flex flex-col">
                  <div className="flex items-center justify-between mb-2 ml-4 text-sm">
                    <div>
                      Selected Contacts (<span>{selectedContacts.length}</span>)
                    </div>
                    <div
                      className="text-sm cursor-pointer text-[#024773] font-[500]"
                      onClick={handleClearSelectedContact}
                    >
                      Clear All
                    </div>
                  </div>
                  <div className="max-h-96 overflow-y-auto ml-4">
                    {selectedContacts.map((contact, index) => (
                      <div
                        key={index}
                        className=" bg-[#FBF9F9] contact-item transition duration-300 ease-in-out hover:bg-gray-300 flex justify-between p-2 relative rounded-md mb-1"
                      >
                        <div className="flex items-center">
                          <div className="rounded-full overflow-hidden h-9 w-9">
                            <img
                              className="h-full w-full object-cover"
                              src={contact?.url}
                              alt={contact.fullName}
                            />
                          </div>
                          <div className="mx-8">
                            <div className="flex-1">
                              <div className="text-left text-sm">
                                {contact.fullName}
                              </div>
                              <div className="text-sm text-gray-500 text-left">
                                {contact.mobileNumber}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="cursor-pointer absolute right-4 top-1/2 transform -translate-y-1/2"
                          onClick={() => removeContact(index)}
                        >
                          <span className="text-2xl">&times;</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="md:w-full min-w-72 flex justify-center flex-col mt-4">
              <div className="flex justify-center w-full">
                <Button
                  lable={"Send Now"}
                  gredient={true}
                  mainStyle={`w-full md:w-[40%] max-w-[20rem] ${
                    selectedContacts.length ? "" : "cursor-not-allowed"
                  }`}
                  buttonClick={handleSendInvitation}
                  disable={!selectedContacts.length}
                />
              </div>
              <div className="flex justify-center mt-3 w-full">
                <button
                  className={`w-full md:w-[40%] max-w-[20rem] text-[#024773] text-sm ${
                    selectedContacts.length ? "" : "cursor-not-allowed"
                  }`}
                  onClick={handleSaveAndSendInvitation}
                  disabled={!selectedContacts.length}
                >
                  Save and Send later
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <InvitationSuccess />
  );
};

const mapStateToProps = (state: any) => {
  return {
    contactsData: state.googleContactReducer,
    freeCredits: state.getProfileReducer.data?.freeCredits,
    sendInvitation: state.sendInvitationReducer,
  };
};

export default connect(mapStateToProps)(ContactPicker);
