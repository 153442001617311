import { put, call } from "redux-saga/effects";
import { authApi } from "../../../Utils/Constant";
import toast from "react-hot-toast";
import { POST_API } from "../../../Services/API/ApiInstance";
import { sendInvitationSuccess, sendInvitationFailure } from "../../Actions";

function* sendInvitationSaga(body: any): Generator<any, void, any> {
  const data = body.payload.data;
  const test = body.payload.test;
  try {
    const res = yield call(
      POST_API,
      authApi.SEND_INVITATION + "?isTest=" + test,
      data
    );
    console.log(res);

    toast.success(res.message);
    yield put(sendInvitationSuccess(res));
  } catch (error: any) {
    toast.error(error?.response?.data?.message);
    yield put(sendInvitationFailure(error));
  }
}

export default sendInvitationSaga;
