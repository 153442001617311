import {
  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAILURE,
} from "../../../Utils/Constant";

const initialState = {
  data: {},
  loading: false,
  error: null,
};
export const sendInvitationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SEND_INVITATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SEND_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case SEND_INVITATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
