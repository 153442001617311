// API END-POINTS FOR AUTH APIs
export const authApi = {
  LOG_IN: "v1/user/login",
  SIGN_UP: "v1/user/register",
  REGISTER_OTP_VERFICATION: "v1/user/register/verifyOTP",
  LOGIN_OTP_VERFICATION: "v1/user/login/verifyOTP",
  REGISTER_RESEND_OTP: "v1/user/register/resendOTP",
  PROFILE: "v1/user/profile",
  EVENTS_CATEGORY: "/v1/category",
  EVENTS_SUB_CATEGORY: "v1/category/sub-category?",
  CREATE_EVENTS: "v1/event/create",
  GET_EVENTS: "v1/event/all",
  GET_EVENT_BY_ID: "v1/event/",
  DELETE_EVENT: "v1/event/delete/",
  UPDATE_EVENT: "v1/event/update",
  DELETE_USER_ACCOUNT: "v1/user",
  GET_CONTACT: "v1/contact/all",
  GET_CREDIT_PLAN: "v1/credit/plan/all",
  SAVE_CONTACT: "v1/contact/save",
  UPDATE_PROFILE: "v1/user/uploadProfilePic",
  UPDATE_PAYMENT: "v1/credit/updatePayment",
  GET_PURCHASE_HISTORY: "v1/user/purchaseHistory",
  SEND_INVITATION: "v1/saveContactsAndSendInvitation",
};

//LOGIN TYPES
export const POST_LOG_IN_REQUEST = "POST_LOG_IN_REQUEST";
export const POST_LOG_IN_SUCCESS = "POST_LOG_IN_SUCCESS";
export const POST_LOG_IN_FAILURE = "POST_LOG_IN_FAILURE";
export const POST_LOG_IN_EMPTY = "POST_LOG_IN_EMPTY";

// SIGNUP TYPES
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
export const SIGN_UP_EMPTY = "SIGN_UP_EMPTY";

// REGISTER OTP VERIFICATION
export const VERFY_SIGUP_OTP_REQUEST = "VERFY_SIGUP_OTP_REQUEST";
export const VERFY_SIGUP_OTP_SUCCESS = "VERFY_SIGUP_OTP_SUCCESS";
export const VERFY_SIGUP_OTP_FAILURE = "VERFY_SIGUP_OTP_FAILURE";
export const VERFY_SIGUP_OTP_EMPTY = "VERFY_SIGUP_OTP_EMPTY";

// RESEND OTP REGISTER
export const REGISTER_RESEND_OTP_REQUEST = "REGISTER_RESEND_OTP_REQUEST";
export const REGISTER_RESEND_OTP_SUCCESS = "REGISTER_RESEND_OTP_SUCCESS";
export const REGISTER_RESEND_OTP_FAILURE = "REGISTER_RESEND_OTP_FAILURE";

// REGISTER OTP VERIFICATION
export const VERFY_LOGIN_OTP_REQUEST = "VERFY_LOGIN_OTP_REQUEST";
export const VERFY_LOGIN_OTP_SUCCESS = "VERFY_LOGIN_OTP_SUCCESS";
export const VERFY_LOGIN_OTP_FAILURE = "VERFY_LOGIN_OTP_FAILURE";
export const VERFY_LOGIN_OTP_EMPTY = "VERFY_LOGIN_OTP_EMPTY";

// GET PROFILE INFO
export const GET_PROFILE_INFO_REQUEST = "GET_PROFILE_INFO_REQUEST";
export const GET_PROFILE_INFO_SUCCESS = "GET_PROFILE_INFO_SUCCESS";
export const GET_PROFILE_INFO_FAILURE = "GET_PROFILE_INFO_FAILURE";

// GET MAIN CATEGORY
export const GET_MAIN_CATEGORY_REQUEST = "GET_MAIN_CATEGORY_REQUEST";
export const GET_MAIN_CATEGORY_SUCCESS = "GET_MAIN_CATEGORY_SUCCESS";
export const GET_MAIN_CATEGORY_FAILURE = "GET_MAIN_CATEGORY_FAILURE";

// GET MAIN CATEGORY
export const GET_SUB_CATEGORY_REQUEST = "GET_SUB_CATEGORY_REQUEST";
export const GET_SUB_CATEGORY_SUCCESS = "GET_SUB_CATEGORY_SUCCESS";
export const GET_SUB_CATEGORY_FAILURE = "GET_SUB_CATEGORY_FAILURE";

// CREATE EVENT
export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";

// GET EVENT
export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";

// Get EVENT BY ID
export const GET_EVENT_ID_REQUEST = "GET_EVENT_ID_REQUEST";
export const GET_EVENT_ID_SUCCESS = "GET_EVENT_ID_SUCCESS";
export const GET_EVENT_ID_FAILURE = "GET_EVENT_ID_FAILURE";
export const GET_EVENT_ID_RESET = "GET_EVENT_ID_RESET";

// Delete Event
export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT__FAILURE";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT__SUCCESS";

// Update Event
export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

// Delete User Acccount
export const DELETE_USER_ACCOUNT_REQUEST = "DELETE_USER_ACCOUNT_REQUEST";
export const DELETE_USER_ACCOUNT_SUCCESS = "DELETE_USER_ACCOUNT_SUCCESS";
export const DELETE_USER_ACCOUNT_FAILURE = "DELETE_USER_ACCOUNT_FAILURE";
export const DELETE_USER_ACCOUNT_EMPTY = "DELETE_USER_ACCOUNT_EMPTY";

// GET CONTACTS
export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAILURE = "GET_CONTACT_FAILURE";

// GET CREDIT PLAN
export const GET_CREDIT_PLAN_REQUEST = "GET_CREDIT_PLAN_REQUEST";
export const GET_CREDIT_PLAN_SUCCESS = "GET_CREDIT_PLAN_SUCCESS";
export const GET_CREDIT_PLAN_FAILURE = "GET_CREDIT_PLAN_FAILURE";

// GET GOOGLE CONTACT
export const GET_GOOGLE_CONTACT_REQUEST = "GET_GOOGLE_CONTACT_REQUEST";
export const GET_GOOGLE_CONTACT_SUCCESS = "GET_GOOGLE_CONTACT_SUCCESS";
export const GET_GOOGLE_CONTACT_FAILURE = "GET_GOOGLE_CONTACT_FAILURE";

// SAVE GOOGLE CONTACT
export const SAVE_GOOGLE_CONTACT_REQUEST = "SAVE_GOOGLE_CONTACT_REQUEST";
export const SAVE_GOOGLE_CONTACT_SUCCESS = "SAVE_GOOGLE_CONTACT_SUCCESS";
export const SAVE_GOOGLE_CONTACT_FAILURE = "SAVE_GOOGLE_CONTACT_FAILURE";

// SAVE GOOGLE CONTACT
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

// UPDATE PAYMENT
export const UPDATE_PAYMENT_REQUEST = "UPDATE_PAYMENT_REQUEST";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAILURE = "UPDATE_PAYMENT_FAILURE";

// GET TRANSITION HISTORY
export const GET_TRANSITION_REQUEST = "GET_TRANSITION_REQUEST";
export const GET_TRANSITION_SUCCESS = "GET_TRANSITION_SUCCESS";
export const GET_TRANSITION_FAILURE = "GET_TRANSITION_FAILURE";


// SEND INVITATION
export const SEND_INVITATION_REQUEST = "SEND_INVITATION_REQUEST";
export const SEND_INVITATION_SUCCESS = "SEND_INVITATION_SUCCESS";
export const SEND_INVITATION_FAILURE = "SEND_INVITATION_FAILURE";