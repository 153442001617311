import React, { useState } from "react";
import { HomeLogo } from "../Asset";
import RememberMe from "../Component/InputComponent/RememberMe";
import Button from "../Component/AuthButton/Button";
import FormInvities from "../Component/EventForm/FormInvities";
import "../index.css";

const InvitePage = () => {
  const [toggle, setToogle] = useState(false);
  const [foodType, setFoodType] = useState("");
  const [invities, setInvities] = useState(false);
  const eventName = "First Birthday Party of Avinash";
  const date = "Sunday, 14 April 2024";
  const time = "7:00 pm IST";
  const place = "Moksha Banquets";
  const googleLink = "https://maps.app.goo.gl/LAQN1grfZxXwi1Ld9";
  const detailedAdd =
    "5-4-187/6/6, PV Narasimha Rao Marg, Karbala Maidan, Rani Gunj, Secunderabad 500003, India";

  const handleSubmitForm = () => {};
  const handleInvities = (data: any) => {};
  return (
    <div className="flex flex-col h-screen">
      <nav className="bg-[#F4E4E4] px-6 md:flex block items-center">
        <img src={HomeLogo} alt="Logo" className="h-16 mr-4" />
      </nav>
      <div className="flex flex-col items-center w-full h-full shadow bg-[#F4E4E4]">
        <p className="text-[#024773] text-[1.5rem] font-bold mb-6">
          {eventName}
        </p>
        <div className="w-[90%] md:w-[65%] xl:w-1/2 h-fit bg-white md:px-16 xl:px-20 px-8 flex flex-col">
          <div className="text-center my-8 text-[#024773] text-[1.2rem] font-bold">
            You are invited to
          </div>
          <div className="mx-auto text-center text-[#989898] text-[0.8rem] font-[200]">
            {`The ${eventName} on ${date} from ${time} at ${place} `}
            <a
              href={googleLink}
              target="_blank"
              className="underline text-blue-500"
            >
              {"(Google Link)"}{" "}
            </a>
            {detailedAdd}
          </div>

          <div className="w-full flex flex-col items-center justify-center">
            <div className="w-full md:w-[80%]  my-12">
              <FormInvities
                readOnly={false}
                messageStyle={"my-2 text-center"}
                disabled={false}
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-center justify-center">
            <div className="w-full md:w-[80%]">
              <RememberMe
                label={""}
                checked={toggle}
                onChange={(data) => setToogle(data)}
                message={"Want to enable Food Preference?"}
                messageStyle="text-[#676767]"
                isReviewed={false}
              />
              {toggle && (
                <div className="flex my-4 flex-col gap-1">
                  <label>
                    <input
                      className="text-[0.8rem]"
                      type="radio"
                      name="foodType"
                      value="Strict Vegetarian"
                      onChange={(e) => setFoodType(e.target.value)}
                    />
                    <span className="text-[0.8rem] text-[#969696] mx-2">
                      Strict Vegetarian
                    </span>
                  </label>
                  <label>
                    <input
                      className="text-[0.8rem]"
                      type="radio"
                      name="foodType"
                      value=" Vegetarian & Non Vegetarian"
                      onChange={(e) => setFoodType(e.target.value)}
                    />

                    <span className="text-[0.8rem] text-[#969696] mx-2">
                      Vegetarian & Non Vegetarian
                    </span>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex justify-center my-14">
            <Button
              lable={"Send your Response"}
              mainStyle="w-72"
              gredient={true}
              buttonClick={handleSubmitForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitePage;
