import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./Utils/ProtectedRoutes";
import { LogInPage, SignUpPage } from "./Screens/Auth";
import LandingPage from "./Screens/LandingPage";
import { EventHome } from "./Screens/Events";
import EventForm from "./Screens/Events/EventForm";
import Profile from "./Screens/Profile/Profile";
import Gallery from "./Screens/Gallery/Gallery";
import InvitePage from "./Screens/InvitePage";
import Vender from "./Screens/Vender/Vender";
import MyCredit from "./Screens/MyCredit/MyCredit";
import BuyCredit from "./Screens/Buy Credit/BuyCredit";
import Reports from "./Screens/Report/Reports";
import ContactPicker from "./Component/EventForm/ContactPicker";
import TestInvitationResponse from "./Component/EventForm/TestInvitationResponse";
import OAuthMain from "./Component/GoogleOAuth/OAuthMain";

function App() {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<EventHome />} />
        <Route path="/events/form/:id?" element={<EventForm />} />
        <Route
          path="/events/form/:id?/testResponse"
          element={<TestInvitationResponse />}
        />
        <Route path="/events/form/:id?/contacts" element={<ContactPicker />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/vender" element={<Vender />} />
        <Route path="/mycredit" element={<MyCredit />} />
        <Route path="/buycredit" element={<BuyCredit />} />
        <Route path="/report" element={<Reports />} />
      </Route>
      <Route path="/login" element={<LogInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/home" element={<LandingPage />} />
      <Route path="*" element={<Navigate to="/home" />} />
      <Route path="/rsvp" element={<InvitePage />} />
    </Routes>
  );
}
export default App;
