import {
  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAILURE,
} from "../../../Utils/Constant";

export const sendInvitationRequest = (data: object, test: boolean) => {
  return {
    type: SEND_INVITATION_REQUEST,
    payload: { data, test },
  };
};

export const sendInvitationSuccess = (data: object) => {
  return {
    type: SEND_INVITATION_SUCCESS,
    payload: data,
  };
};

export const sendInvitationFailure = (error: object) => {
  return {
    type: SEND_INVITATION_FAILURE,
    payload: error,
  };
};
