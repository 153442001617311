import { Calendar } from "primereact/calendar";
import React, { useState, useEffect } from "react";
import RememberMe from "../InputComponent/RememberMe";
import {
  convertDate,
  convertStringToDateObject,
} from "../../Utils/dummyStorage/functions";

interface FromCalenderProps {
  labelStyle: string;
  required: boolean | string;
  startDate: any;
  endDate: any;
  startName?: string;
  endName?: string;
  label: string;
  endLabel: string;
  startLabel: string;
  setValue: any;
  getValues?: any;
  placeholder?: string;
  isReviewed: boolean;
}

const FromCalender: React.FC<FromCalenderProps> = ({
  labelStyle,
  required,
  startName = "startDate",
  endName = "endDate",
  label,
  startLabel,
  endLabel,
  setValue,
  getValues,
  isReviewed,
  placeholder,
}) => {
  const [wantEndDate, setWantEndDate] = useState(false);
  const [minEndDate, setMinEndDate] = useState<Date | null>(null);

  useEffect(() => {
    // Update minimum end date when start date changes
    if (getValues("startDate")) {
      setMinEndDate(convertStringToDateObject(getValues("startDate")));
    }
  }, [getValues("startDate")]);

  const handleStartDateChange = (e: Date) => {
    setValue(startName, convertDate(e));
    setMinEndDate(e); // Update minimum end date when start date changes

    // Adjust end date if it's before the new start date
    const currentEndDate = getValues("endDate") ? convertStringToDateObject(getValues("endDate")) : null;
    if (currentEndDate && currentEndDate < e) {
      setValue(endName, convertDate(e));
    }
  };

  return (
    <>
      <div className="w-full py-1">
        <p
          className={`${labelStyle} text-left text-[0.9rem] text-[#024773] my-2 font-[300]`}
        >
          {startLabel} {required && <span className={""}>*</span>}
        </p>
        <div className="px-4 bg-white rounded shadow py-[0.35rem]">
          <div className="">
            <Calendar
              id="startDate"
              value={convertStringToDateObject(getValues(startName))}
              placeholder={placeholder}
              panelClassName="custom-calendar-panel text-[0.9rem]"
              todayButtonClassName="text-[0.8rem]"
              className="w-[99%] text-[0.8rem] "
              inputClassName="active:outline-none focus: outline-none outline-none ring-none"
              onChange={(e) => handleStartDateChange(e.value || new Date())}
              showIcon
              variant={undefined}
              disabled={isReviewed}
              minDate={new Date()}
              dateFormat="DD, dd MM, yy"
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        <RememberMe
          label={""}
          messageStyle={
            "text-[0.68rem] text-[#024773] text-left flex items-center"
          }
          checked={getValues("isEndDateEnabled")}
          onChange={(data) => {
            setWantEndDate(data);
            setValue("isEndDateEnabled", data);
          }}
          message={"Want to add end date?"}
          isReviewed={isReviewed}
        />
      </div>
      {getValues("isEndDateEnabled") && (
        <div className="w-full">
          <p
            className={`${labelStyle} text-left text-[0.9rem] text-[#024773] my-2 font-[300]`}
          >
            {endLabel} {required && <span className={""}>*</span>}
          </p>
          <div className="px-4 bg-white rounded shadow py-[0.35rem]">
            <div className="">
              <Calendar
                id="endDate"
                value={convertStringToDateObject(getValues("endDate"))}
                placeholder={placeholder}
                panelClassName="custom-calendar-panel text-[0.9rem]"
                todayButtonClassName="text-[0.8rem]"
                className="w-[99%] text-[0.8rem] "
                inputClassName="active:outline-none focus: outline-none outline-none ring-none"
                onChange={(e) => {
                  setValue(endName, convertDate(e.value));
                }}
                showIcon
                variant={undefined}
                disabled={isReviewed}
                minDate={minEndDate ?? undefined} // Set minimum date for the end date
                dateFormat="DD, dd MM, yy"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FromCalender;
