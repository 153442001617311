import axios from "axios";
// import { baseURL } from "./ApiConfig";
const baseURL = "https://invitepro-stag-apis.up.railway.app/invitepro/";

let token = sessionStorage.getItem("token") || localStorage.getItem("token");
const instance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "X-Custom-Header": "foobar",
    "Content-Type": "application/json",
    accessToken: token,
  },
});

instance.interceptors.request.use(
  async (config) => {
    const accessToken = sessionStorage.getItem("token");
    if (accessToken) {
      config.headers["accessToken"] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const GET_API = async (path: string, params?: object) => {
  try {
    const config = {
      params: params,
    };
    const res = await instance.get(path, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const POST_API = async (path: string, data: object, params?: object) => {
  try {
    const config = {
      params: params,
    };

    const res = await instance.post(path, data, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const PUT_API = async (path: string, data: object, params?: object) => {
  try {
    const config = {
      params: params,
    };
    const res = await instance.put(path, data, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const DELETE_API = async (path: string, params?: object) => {
  try {
    const config = {
      params: params,
    };
    const res = await instance.delete(path, config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const PATCH_API = async (
  path: string,
  data: object,
  params?: object
) => {
  try {
    const config = {
      params: params,
    };
    const res = await instance.patch(path, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
