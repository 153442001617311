import React from "react";
import FormHeader from "../../Component/EventForm/FormHeader";
import { FaChevronDown } from "react-icons/fa6";
import ReportDDCount from "../../Component/EventForm/ReportDDCount";
import Button from "../../Component/AuthButton/Button";
import { contacts } from "../../Utils/dummyStorage/data";

const Reports = () => {
  const handleBack = () => {};

  return (
    <div className="homeScreen md:w-[80vw] w-full right-0 absolute top-12 text-center overflow-clip">
      <div className="h-full flex flex-col items-center justify-center w-full text-center">
        <FormHeader header={"Report"} message={""} onBack={handleBack} />
        <div className="h-full w-full flex justify-center my-6 overflow-y-auto overflow-x-hidden">
          <div className="md:w-[80%] w-[90%] lg:w-3/4 xl:w-3/4 h-max md:px-8 max-w-[40rem] px-6 py-10 bg-white flex justify-center flex-col items-center pb-16">
            <ReportDDCount
              list={contacts}
              count={contacts.length}
              title={"Invitations sent successfuly"}
            />
            <ReportDDCount
              list={contacts}
              count={contacts.length}
              title={"Unsuccessful invitation"}
            />
            <p className="text-xs text-[#4d4d4d] text-left w-full mb-3">
              *Invitation could not be sent due to Telephone number not in
              service
            </p>
            <ReportDDCount
              list={contacts}
              count={contacts.length}
              title={"Viewed the invitation"}
            />
            <div className="text-[0.8rem] text-left w-full mb-3">
              Invitation response
              <ul className="list-disc px-6 py-2 font-[500]">
                <li>
                  <span className="cursor-pointer">Yes ({0})</span>
                </li>
                <li>
                  <span className="cursor-pointer">No ({0})</span>
                </li>
              </ul>
            </div>
            <ReportDDCount
              list={contacts}
              count={contacts.length}
              title={"Not Viewed the invitation"}
            />
            <ReportDDCount
              list={contacts}
              count={contacts.length}
              title={"Total number of attendees"}
            />
            <Button
              lable={"Back"}
              gredient={true}
              mainStyle="w-1/2"
              buttonClick={function (data: object): void {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
