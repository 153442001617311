import { MdLocationSearching } from "react-icons/md";
import React from "react";
import { getLocation } from "../../Utils/GoogleLocation";
interface FromInputComponentProps {
  label: string;
  name: string;
  setValue?: any;
  mianStyle: string;
  inputStyle: string;
  error: string;
  errorMessage: string;
  labelStyle: string;
  required: boolean | string;
  disabled: boolean;
  type: string;
  register: any;
  locationMessage?: string;
  pickMyLocation?: boolean;
  placeholder?: string;
  value?: any;
}
const FromInputComponent: React.FC<FromInputComponentProps> = ({
  label,
  name,
  setValue,
  mianStyle,
  inputStyle,
  error,
  errorMessage,
  labelStyle,
  required,
  disabled,
  type,
  register,
  locationMessage,
  pickMyLocation,
  placeholder,
}) => {
  return (
    <div className={`w-full ${mianStyle}`}>
      <p
        className={`${labelStyle} text-left text-[0.9rem] text-[#024773] my-2 font-[300]`}
      >
        {label} {required && <span className={""}>*</span>}
      </p>
      <input
        key={name}
        className={`w-full py-2 bg-white border border-gray-300 rounded shadow active:outline-none focus:outline-none px-2 text-[0.8rem] ${inputStyle} ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        {...register(name, { required: required })}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          setValue(name, value);
        }}
      />
      {pickMyLocation && (
        <div className="text-[0.7rem] text-[#024773] text-left p-1 flex items-center gap-2">
          <span
            className="cursor-pointer flex items-center gap-1"
            onClick={() => getLocation(setValue)}
          >
            <MdLocationSearching />
            <p>{locationMessage}</p>
          </span>
        </div>
      )}
      {error && (
        <div className="text-[0.7rem] text-left text-red-600">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default FromInputComponent;
