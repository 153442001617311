import React, { useEffect, useState } from "react";
import { FaRegClock } from "react-icons/fa6";
import { TimePicker } from "react-time-picker-typescript"; // Import TimePicker
import "react-time-picker-typescript/dist/style.css";
import "../../../src/index.css";
interface FormTimeProps {
  register: any;
  startDate: string | undefined;
  endDate: string | undefined;
  isReviewed: boolean;
  setValue?: any;
  getValues?: any;
}
const FormTime: React.FC<FormTimeProps> = ({
  register,
  startDate,
  endDate,
  isReviewed,
  setValue,
  getValues,
}) => {
  const handleStartDate = (value: any) => {
    console.log("handleStartDate", value.target.value);
    setValue(startDate, value.target.value);
  };

  const handleEndDate = (value: any) => {
    console.log("handleEndDate", value.target.value);
    setValue(endDate, value.target.value);
  };

  return (
    <div className="w-full my-2">
      <div className="text-left text-[#024773] font-[300]">Time</div>
      <div className="flex justify-between gap-3">
        <div className="relative bg-white w-1/2 rounded">
          <input
            type="time"
            name={startDate}
            {...register(startDate)}
            onChange={(value: any) => handleStartDate(value)}
            disabled={isReviewed}
            // value={getValues(startDate) || null} // Ensure value is defined
            className="w-full text-[0.8rem] p-1 rounded outline-none focus:outline-none disabled:opacity-50"
          />
        </div>
        <div className="relative bg-white w-1/2 rounded">
          <input
            type="time"
            name={endDate}
            {...register(endDate)}
            onChange={(value: any) => handleEndDate(value)}
            disabled={isReviewed}
            // value={getValues(endDate) || null} // Ensure value is defined
            className="w-full text-[0.8rem] p-1 rounded outline-none focus:outline-none disabled:opacity-50"
          />
        </div>
      </div>
    </div>
  );
};
export default FormTime;
